import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Links = _resolveComponent("Links")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.nodesComp))),
    (_ctx.leftNodesComp)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.leftNodesComp), { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.rightNodesComp)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.rightNodesComp), { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.branches)
      ? (_openBlock(), _createBlock(_component_Links, {
          key: 2,
          links: _ctx.unref(_ctx.branches)
        }, null, 8, ["links"]))
      : _createCommentVNode("", true)
  ], 64))
}