import { resolveDynamicComponent as _resolveDynamicComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MarkNode = _resolveComponent("MarkNode")!
  const _component_Node = _resolveComponent("Node")!

  return (_ctx.unref(_ctx.visibility) !== _ctx.Visibility.None)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        style: _normalizeStyle([
            {
                width: _ctx.unref(_ctx.width) + 'px',
                height: _ctx.unref(_ctx.height) + 'px',
                visibility: _ctx.unref(_ctx.visibility) === _ctx.Visibility.Hidden ? 'hidden' : undefined
            },
            _ctx.unref(_ctx.style) ?? {}
        ]),
        class: _normalizeClass({
            bar: true,
            ..._ctx.unref(_ctx.classes)
        })
      }, [
        _createElementVNode("div", {
          class: "overlayTextContainer border",
          style: _normalizeStyle([
                { width: _ctx.unref(_ctx.width) + 'px', height: _ctx.unref(_ctx.height) + 'px' },
                _ctx.unref(_ctx.borderStyle) ?? {}
            ])
        }, [
          (_ctx.component)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component), {
                key: 0,
                class: "overlayText",
                style: _normalizeStyle(_ctx.unref(_ctx.textStyle))
              }, null, 8, ["style"]))
            : _createCommentVNode("", true)
        ], 4),
        _createElementVNode("div", {
          class: "border",
          style: _normalizeStyle([
                { width: _ctx.unref(_ctx.width) + 'px', height: _ctx.unref(_ctx.height) + 'px' },
                _ctx.unref(_ctx.style) ?? {},
                _ctx.unref(_ctx.baseStyle) ?? {},
                _ctx.unref(_ctx.borderStyle) ?? {}
            ])
        }, [
          _createElementVNode("div", {
            class: "fill",
            style: _normalizeStyle([_ctx.barStyle, _ctx.unref(_ctx.style) ?? {}, _ctx.unref(_ctx.fillStyle) ?? {}])
          }, null, 4)
        ], 4),
        _createVNode(_component_MarkNode, {
          mark: _ctx.unref(_ctx.mark)
        }, null, 8, ["mark"]),
        _createVNode(_component_Node, { id: _ctx.id }, null, 8, ["id"])
      ], 6))
    : _createCommentVNode("", true)
}