
import projInfo from "data/projInfo.json";
import { CoercableComponent, StyleValue } from "features/feature";
import { FeatureNode } from "game/layers";
import { Persistent } from "game/persistence";
import player from "game/player";
import { computeComponent, processedPropType, wrapRef } from "util/vue";
import { computed, defineComponent, nextTick, PropType, Ref, ref, toRefs, unref, watch } from "vue";
import Context from "./Context.vue";

export default defineComponent({
    components: { Context },
    props: {
        index: {
            type: Number,
            required: true
        },
        tab: {
            type: Function as PropType<() => HTMLElement | undefined>,
            required: true
        },
        display: {
            type: processedPropType<CoercableComponent>(Object, String, Function),
            required: true
        },
        minimized: {
            type: Object as PropType<Persistent<boolean>>,
            required: true
        },
        minWidth: {
            type: processedPropType<number | string>(Number, String),
            required: true
        },
        name: {
            type: processedPropType<string>(String),
            required: true
        },
        color: processedPropType<string>(String),
        style: processedPropType<StyleValue>(String, Object, Array),
        classes: processedPropType<Record<string, boolean>>(Object),
        minimizable: processedPropType<boolean>(Boolean),
        nodes: {
            type: Object as PropType<Ref<Record<string, FeatureNode | undefined>>>,
            required: true
        }
    },
    setup(props) {
        const { display, index, minimized, minWidth, tab } = toRefs(props);

        const component = computeComponent(display);
        const showGoBack = computed(
            () => projInfo.allowGoBack && index.value > 0 && !minimized.value
        );

        function goBack() {
            player.tabs.splice(unref(props.index), Infinity);
        }

        nextTick(() => updateTab(minimized.value, unref(minWidth.value)));
        watch([minimized, wrapRef(minWidth)], ([minimized, minWidth]) =>
            updateTab(minimized, minWidth)
        );

        const contextRef = ref<typeof Context | null>(null);
        watch(
            () => contextRef.value?.nodes,
            nodes => {
                if (nodes) {
                    props.nodes.value = nodes;
                }
            }
        );

        function updateTab(minimized: boolean, minWidth: number | string) {
            const width =
                typeof minWidth === "number" || Number.isNaN(parseInt(minWidth))
                    ? minWidth + "px"
                    : minWidth;
            const tabValue = tab.value();
            if (tabValue != undefined) {
                if (minimized) {
                    tabValue.style.flexGrow = "0";
                    tabValue.style.flexShrink = "0";
                    tabValue.style.width = "60px";
                    tabValue.style.minWidth = tabValue.style.flexBasis = "";
                    tabValue.style.margin = "0";
                } else {
                    tabValue.style.flexGrow = "";
                    tabValue.style.flexShrink = "";
                    tabValue.style.width = "";
                    tabValue.style.minWidth = tabValue.style.flexBasis = width;
                    tabValue.style.margin = "";
                }
            }
        }

        return {
            component,
            showGoBack,
            contextRef,
            unref,
            goBack
        };
    }
});
