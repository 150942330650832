import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5e580749"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MarkNode = _resolveComponent("MarkNode")!
  const _component_Node = _resolveComponent("Node")!

  return (_ctx.unref(_ctx.visibility) !== _ctx.Visibility.None)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        style: _normalizeStyle([
            {
                visibility: _ctx.unref(_ctx.visibility) === _ctx.Visibility.Hidden ? 'hidden' : undefined
            },
            _ctx.notifyStyle,
            _ctx.unref(_ctx.style) ?? {}
        ]),
        class: _normalizeClass({
            feature: true,
            challenge: true,
            done: _ctx.unref(_ctx.completed),
            canStart: _ctx.unref(_ctx.canStart) && !_ctx.unref(_ctx.maxed),
            maxed: _ctx.unref(_ctx.maxed),
            ..._ctx.unref(_ctx.classes)
        })
      }, [
        _createElementVNode("button", {
          class: "toggleChallenge",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args))),
          disabled: !_ctx.unref(_ctx.canStart) || _ctx.unref(_ctx.maxed)
        }, _toDisplayString(_ctx.buttonText), 9, _hoisted_1),
        (_ctx.unref(_ctx.comp))
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.unref(_ctx.comp)), { key: 0 }))
          : _createCommentVNode("", true),
        _createVNode(_component_MarkNode, {
          mark: _ctx.unref(_ctx.mark)
        }, null, 8, ["mark"]),
        _createVNode(_component_Node, { id: _ctx.id }, null, 8, ["id"])
      ], 6))
    : _createCommentVNode("", true)
}