import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8ef24874"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "material-icons pinned"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tooltip-container", { shown: _ctx.isShown }]),
    onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isHovered = true)),
    onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isHovered = false)),
    onClickCapture: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.togglePinned && _ctx.togglePinned(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    (_ctx.elementComp)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.elementComp), { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.isShown)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["tooltip", {
                    top: _ctx.unref(_ctx.direction) === _ctx.Direction.Up,
                    left: _ctx.unref(_ctx.direction) === _ctx.Direction.Left,
                    right: _ctx.unref(_ctx.direction) === _ctx.Direction.Right,
                    bottom: _ctx.unref(_ctx.direction) === _ctx.Direction.Down,
                    ..._ctx.unref(_ctx.classes)
                }]),
              style: _normalizeStyle([
                    {
                        '--xoffset': _ctx.unref(_ctx.xoffset) || '0px',
                        '--yoffset': _ctx.unref(_ctx.yoffset) || '0px'
                    },
                    _ctx.unref(_ctx.style) ?? {}
                ])
            }, [
              (_ctx.showPin)
                ? (_openBlock(), _createElementBlock("span", _hoisted_1, "push_pin"))
                : _createCommentVNode("", true),
              (_ctx.comp)
                ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.comp), { key: 1 }))
                : _createCommentVNode("", true)
            ], 6))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 34))
}