
import Node from "components/Node.vue";
import MarkNode from "components/MarkNode.vue";
import { CoercableComponent, StyleValue, Visibility } from "features/feature";
import {
    computeOptionalComponent,
    isCoercableComponent,
    processedPropType,
    setupHoldToClick
} from "util/vue";
import { defineComponent, PropType, toRefs, unref } from "vue";

export default defineComponent({
    props: {
        display: processedPropType<CoercableComponent>(Object, String, Function),
        visibility: {
            type: processedPropType<Visibility>(Number),
            required: true
        },
        style: processedPropType<StyleValue>(String, Object, Array),
        classes: processedPropType<Record<string, boolean>>(Object),
        onClick: Function as PropType<(e?: MouseEvent | TouchEvent) => void>,
        onHold: Function as PropType<VoidFunction>,
        color: processedPropType<string>(String),
        glowColor: processedPropType<string>(String),
        canClick: {
            type: processedPropType<boolean>(Boolean),
            required: true
        },
        mark: processedPropType<boolean | string>(Boolean, String),
        id: {
            type: String,
            required: true
        }
    },
    components: {
        MarkNode,
        Node
    },
    setup(props) {
        const { onClick, onHold, display } = toRefs(props);

        const comp = computeOptionalComponent(display);

        const { start, stop } = setupHoldToClick(onClick, onHold);

        return {
            start,
            stop,
            comp,
            unref,
            Visibility,
            isCoercableComponent
        };
    }
});
