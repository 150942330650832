
import "components/common/table.css";
import { GenericTreeNode, TreeBranch } from "features/trees/tree";
import { coerceComponent, processedPropType, renderJSX, unwrapRef } from "util/vue";
import { Component, defineComponent, shallowRef, toRefs, unref, watchEffect } from "vue";
import Links from "features/links/Links.vue";
import { jsx } from "features/feature";

export default defineComponent({
    props: {
        nodes: {
            type: processedPropType<GenericTreeNode[][]>(Array),
            required: true
        },
        leftSideNodes: processedPropType<GenericTreeNode[]>(Array),
        rightSideNodes: processedPropType<GenericTreeNode[]>(Array),
        branches: processedPropType<TreeBranch[]>(Array)
    },
    components: { Links },
    setup(props) {
        const { nodes, leftSideNodes, rightSideNodes } = toRefs(props);

        const nodesComp = shallowRef<Component | "">();
        watchEffect(() => {
            const currNodes = unwrapRef(nodes);
            nodesComp.value = coerceComponent(
                jsx(() => (
                    <>
                        {currNodes.map(row => (
                            <span class="row tree-row" style="margin: 50px auto;">
                                {row.map(renderJSX)}
                            </span>
                        ))}
                    </>
                ))
            );
        });

        const leftNodesComp = shallowRef<Component | "">();
        watchEffect(() => {
            const currNodes = unwrapRef(leftSideNodes);
            leftNodesComp.value = currNodes
                ? coerceComponent(
                      jsx(() => (
                          <span class="left-side-nodes small">{currNodes.map(renderJSX)}</span>
                      ))
                  )
                : "";
        });

        const rightNodesComp = shallowRef<Component | "">();
        watchEffect(() => {
            const currNodes = unwrapRef(rightSideNodes);
            rightNodesComp.value = currNodes
                ? coerceComponent(
                      jsx(() => <span class="side-nodes small">{currNodes.map(renderJSX)}</span>)
                  )
                : "";
        });

        return {
            unref,
            nodesComp,
            leftNodesComp,
            rightNodesComp
        };
    }
});
