import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MarkNode = _resolveComponent("MarkNode")!
  const _component_Node = _resolveComponent("Node")!

  return (_ctx.unref(_ctx.visibility) !== _ctx.Visibility.None)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        style: _normalizeStyle([
            { visibility: _ctx.unref(_ctx.visibility) === _ctx.Visibility.Hidden ? 'hidden' : undefined },
            _ctx.unref(_ctx.style) ?? []
        ]),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
        onMousedown: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.start && _ctx.start(...args))),
        onMouseleave: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.stop && _ctx.stop(...args))),
        onMouseup: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.stop && _ctx.stop(...args))),
        onTouchstart: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.start && _ctx.start(...args))),
        onTouchend: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.stop && _ctx.stop(...args))),
        onTouchcancel: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.stop && _ctx.stop(...args))),
        class: _normalizeClass({
            feature: true,
            clickable: true,
            can: _ctx.unref(_ctx.canClick),
            locked: !_ctx.unref(_ctx.canClick),
            small: _ctx.small,
            ..._ctx.unref(_ctx.classes)
        })
      }, [
        (_ctx.unref(_ctx.comp))
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.unref(_ctx.comp)), { key: 0 }))
          : _createCommentVNode("", true),
        _createVNode(_component_MarkNode, {
          mark: _ctx.unref(_ctx.mark)
        }, null, 8, ["mark"]),
        _createVNode(_component_Node, { id: _ctx.id }, null, 8, ["id"])
      ], 38))
    : _createCommentVNode("", true)
}