import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6f164fe2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MarkNode = _resolveComponent("MarkNode")!
  const _component_Node = _resolveComponent("Node")!

  return (_ctx.unref(_ctx.visibility) !== _ctx.Visibility.None)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        style: _normalizeStyle([
            {
                visibility: _ctx.unref(_ctx.visibility) === _ctx.Visibility.Hidden ? 'hidden' : undefined
            },
            _ctx.unref(_ctx.style) ?? {}
        ]),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.purchase && _ctx.purchase(...args))),
        class: _normalizeClass({
            feature: true,
            upgrade: true,
            can: _ctx.unref(_ctx.canPurchase),
            locked: !_ctx.unref(_ctx.canPurchase),
            bought: _ctx.unref(_ctx.bought),
            ..._ctx.unref(_ctx.classes)
        }),
        disabled: !_ctx.unref(_ctx.canPurchase)
      }, [
        (_ctx.unref(_ctx.component))
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.unref(_ctx.component)), { key: 0 }))
          : _createCommentVNode("", true),
        _createVNode(_component_MarkNode, {
          mark: _ctx.unref(_ctx.mark)
        }, null, 8, ["mark"]),
        _createVNode(_component_Node, { id: _ctx.id }, null, 8, ["id"])
      ], 14, _hoisted_1))
    : _createCommentVNode("", true)
}