import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Context = _resolveComponent("Context")!

  return (_openBlock(), _createElementBlock("div", {
    class: "layer-container",
    style: _normalizeStyle({ '--layer-color': _ctx.unref(_ctx.color) })
  }, [
    (_ctx.showGoBack)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "goBack",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goBack && _ctx.goBack(...args)))
        }, "←"))
      : _createCommentVNode("", true),
    (_ctx.minimized.value)
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          class: "layer-tab minimized",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.minimized.value = false))
        }, [
          _createElementVNode("div", null, _toDisplayString(_ctx.unref(_ctx.name)), 1)
        ]))
      : (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass(["layer-tab", [{ showGoBack: _ctx.showGoBack }, _ctx.unref(_ctx.classes)]]),
          style: _normalizeStyle(_ctx.unref(_ctx.style))
        }, [
          _createVNode(_component_Context, { ref: "contextRef" }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component)))
            ]),
            _: 1
          }, 512)
        ], 6)),
    (_ctx.unref(_ctx.minimizable))
      ? (_openBlock(), _createElementBlock("button", {
          key: 3,
          class: "minimize",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.minimized.value = true))
        }, " ▼ "))
      : _createCommentVNode("", true)
  ], 4))
}