import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Node = _resolveComponent("Node")!

  return (_ctx.unref(_ctx.visibility) !== _ctx.Visibility.None)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        style: _normalizeStyle([
            {
                visibility: _ctx.unref(_ctx.visibility) === _ctx.Visibility.Hidden ? 'hidden' : undefined
            },
            _ctx.unref(_ctx.style) ?? {}
        ]),
        class: _normalizeClass({ feature: true, milestone: true, done: _ctx.unref(_ctx.earned), ..._ctx.unref(_ctx.classes) })
      }, [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.unref(_ctx.comp)))),
        _createVNode(_component_Node, { id: _ctx.id }, null, 8, ["id"])
      ], 6))
    : _createCommentVNode("", true)
}