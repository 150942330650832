
import themes from "data/themes";
import { CoercableComponent, jsx, StyleValue } from "features/feature";
import { Persistent } from "game/persistence";
import settings from "game/settings";
import { Direction } from "util/common";
import {
    coerceComponent,
    computeOptionalComponent,
    processedPropType,
    render,
    unwrapRef,
    VueFeature
} from "util/vue";
import {
    Component,
    computed,
    defineComponent,
    PropType,
    ref,
    shallowRef,
    toRefs,
    unref,
    watchEffect
} from "vue";

export default defineComponent({
    props: {
        element: processedPropType<VueFeature>(Object),
        display: {
            type: processedPropType<CoercableComponent>(Object, String, Function),
            required: true
        },
        style: processedPropType<StyleValue>(Object, String, Array),
        classes: processedPropType<Record<string, boolean>>(Object),
        direction: processedPropType<Direction>(String),
        xoffset: processedPropType<string>(String),
        yoffset: processedPropType<string>(String),
        pinned: Object as PropType<Persistent<boolean>>
    },
    setup(props) {
        const { element, display, pinned } = toRefs(props);

        const isHovered = ref(false);
        const isShown = computed(() => (unwrapRef(pinned) || isHovered.value) && comp.value);
        const comp = computeOptionalComponent(display);

        const elementComp = shallowRef<Component | "" | null>(null);
        watchEffect(() => {
            const currComponent = unwrapRef(element);
            elementComp.value =
                currComponent == null
                    ? null
                    : coerceComponent(jsx(() => render(currComponent) as JSX.Element));
        });

        function togglePinned(e: MouseEvent) {
            const isPinned = pinned as unknown as Persistent<boolean> | undefined; // Vue typing :/
            if (e.shiftKey && isPinned) {
                isPinned.value = !isPinned.value;
                e.stopPropagation();
                e.preventDefault();
            }
        }

        const showPin = computed(() => unwrapRef(pinned) && themes[settings.theme].showPin);

        return {
            Direction,
            isHovered,
            isShown,
            comp,
            elementComp,
            unref,
            togglePinned,
            showPin
        };
    }
});
